<dflgr-header-handler [title]="handlerTitle()" [backButton]="true"/>
<div class="handler-content">
	@if (country() && user()) {
		<dflgr-flag-event-list-filtered
			[country]="country()"
			[singleUser]="user()"/>
	}
</div>

<dflgr-handler-action-button icon="flag" [action]="actionButtonAction" label="Add Flag"/>

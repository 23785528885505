import { Component, OnInit, viewChild, ViewContainerRef, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { GENERAL } from './app.constants';
import { AuthService } from './shared/services/auth.service';
import { AlertService } from './shared/services/alert.service';
import { UsersService } from './shared/services/users.service';
import { NavIconComponent } from './shared/components/nav-icon/nav-icon.component';

import { RouterOutlet } from '@angular/router';
import { HeaderAppComponent } from './shared/components/header-app/header-app.component';

const paths = GENERAL.handlers;
const friendsIcon = 'people';
const profileIcon = 'account_circle';

interface MenuItem { icon: string; path: string; text: string; badge?: number; }

@Component({
	selector: 'dflgr-root',
	standalone: true,
	imports: [HeaderAppComponent, RouterOutlet, NavIconComponent],
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	readonly #swUpdate = inject(SwUpdate);
	readonly #alertService = inject(AlertService);
	readonly #infoBar = inject(MatSnackBar);
	readonly #userProvider = inject(UsersService);
	readonly #authProvider = inject(AuthService);

	readonly main = viewChild.required('main', { read: ViewContainerRef });
	readonly title: string = GENERAL.app.title;
	showLogged = false;

	readonly list: MenuItem[] = [
		{ text: 'Home', icon: 'home', path: paths.wall.path },
		{ text: 'Groups', icon: 'group_work', path: paths.groups.path },
		{ text: 'Friends', icon: friendsIcon, path: paths.friends.path },
		{ text: 'Profile', icon: profileIcon, path: '/' }
	];

	private updateLoggedStatus(newLoggedStatus = this.#authProvider.isLogged) {
		if (newLoggedStatus) {
			// if logged, force update local user link
			this.list.find(item => item.icon === profileIcon).path = this.#authProvider.localUserLinkStr;
		}
		this.showLogged = newLoggedStatus;
	}

	ngOnInit() {
		this.updateLoggedStatus();
		this.#authProvider.onLoggedChanged().subscribe(
			logged => { this.updateLoggedStatus(logged); }
		);
		this.#userProvider.getNewFriendsSubscription().subscribe(numReqs => {
			this.list.find(item => item.icon === friendsIcon).badge = numReqs;
		});
		this.#alertService.getProvider().subscribe(alert => {
			this.#infoBar.open(alert.msg, alert.action?.toUpperCase(), {
				viewContainerRef: this.main(),
				duration: alert.duration
			}).afterDismissed().subscribe(({ dismissedByAction }) => {
				if (dismissedByAction && typeof alert.callback === 'function') {
					alert.callback();
				}
			});
		});
		this.checkNewVersion();
	}

	private checkNewVersion() {
		if (this.#swUpdate.isEnabled) {
			this.#swUpdate.versionUpdates.subscribe(ev => {
				if (ev.type === 'VERSION_READY') {
					this.#alertService.publish({
						msg: `${GENERAL.app.title} has been updated! Just click to get latest version`,
						action: 'Refresh',
						callback: () => { window.location.reload(); }
					});
				}
			});
			this.#swUpdate.checkForUpdate();
		}
	}
}

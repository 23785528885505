/* src/app/flags/handlers/countries-list-handler/countries-list-handler.component.scss */
.list {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
}
.list .letter-separator {
  width: 100%;
  padding-left: 5px;
  cursor: default;
}
.list .country-card {
  margin: 5px;
}
.list .country-card ::ng-deep .item-country-flag .flag-container img {
  max-width: 2.25em;
}
.list .country-card ::ng-deep .item-country-flag .name-container {
  font-size: 1.2em;
}
@media (max-width: 599px) {
  .list .country-card {
    width: 100%;
  }
  .list .country-card ::ng-deep .item-country-flag .flag-container .flag-utf {
    font-size: 2.25em;
  }
}
/*# sourceMappingURL=countries-list-handler.component.css.map */

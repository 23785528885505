import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { GENERAL } from '../app.constants';
import { loggedGuard } from '../shared/guards/logged.guard';
import { eventResolver } from './resolvers/event.resolver';

const routes: Routes = [
	{
		path: `${GENERAL.handlers.event.path}/:eventid`,
		loadComponent: () => import('./handlers/event-handler/event-handler.component').then(m => m.EventHandlerComponent),
		resolve: { flagEvent: eventResolver },
		canActivate: [loggedGuard]
	},
	{
		path: `${GENERAL.handlers.countries.path}/:countryid`,
		loadComponent: () => import('./handlers/country-handler/country-handler.component').then(m => m.CountryHandlerComponent),
		canActivate: [loggedGuard],
		children: [
			{
				path: '',
				loadComponent: () => import('./handlers/country-handler/country-handler.component').then(m => m.CountryEventsHandlerComponent)
			},
			{
				path: 'users',
				loadComponent: () => import('./handlers/country-handler/country-handler.component').then(m => m.CountryUsersHandlerComponent)
			},
		]
	},
	{
		path: GENERAL.handlers.countries.path,
		loadComponent: () => import('./handlers/countries-list-handler/countries-list-handler.component').then(m => m.CountriesListHandlerComponent),
	}
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FlagsRoutingModule { }

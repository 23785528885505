import { inject } from '@angular/core';
import { Router, type ActivatedRouteSnapshot, type CanActivateFn } from '@angular/router';
import { UsersService } from '../../shared/services/users.service';
import { of as ObservableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PATHS } from '../../app.constants';
import { AlertService } from '../../shared/services/alert.service';
import { StorageService } from '../../shared/services/storage.service';

/**
 * If self user, go straight ahead.
 * Else, requests user info.
 * If value is truthy, user info was retrieved, and is thus a friend.
 * If value is falsey (request returned error/403/null), user is not a friend.
 */
export const friendGuard: CanActivateFn = (
	next: ActivatedRouteSnapshot,
	// state: RouterStateSnapshot
) => {
	const storageService = inject(StorageService);

	const { user: userNameRequested } = next.params;

	if (userNameRequested === storageService.localUser.username) {
		return ObservableOf(true);
	}

	const userProvider = inject(UsersService);

	return userProvider.getUserInfo(userNameRequested).pipe(
		map((res) => {
			// Save/cache for loading in User resolver
			userProvider.resolvedUser = res;
			return Boolean(res);
		}),
		tap(async (res) => {
			if (!res) {
				const router = inject(Router);
				const alertService = inject(AlertService);

				try {
					await router.navigate([PATHS.friends], { queryParams: { findusername: userNameRequested }});
					alertService.publish({
						msg: `${userNameRequested} is not your Friend or is blocked.`,
						action: 'Ok',
						duration: 4000
					});
				} catch (e) {
					// Discard
				}
			}
		})
	);
};

import { Component, HostBinding } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GENERAL, FORM_CONFIG } from '../../../app.constants';
import { UsersService } from '../../../shared/services/users.service';
import { AlertService } from '../../../shared/services/alert.service';
import type { IAlertMsg } from '../../../shared/services/alert.interfaces';
import type Country from '../../../shared/models/country.model';
import Utils from '../../../shared/app.utils';
import type { Observable } from 'rxjs';
import { takeUntil, tap, map } from 'rxjs/operators';
import { CropperModalComponent } from '../../../shared/components/cropper-modal/cropper-modal.component';
import { SubscribableBaseComponent } from '../../../shared/components/subscribable-base/subscribable-base.component';
import User, { GENDER } from '../../../shared/models/user.model';
import type { ICropperModalData } from '../../../shared/components/cropper-modal/cropper-modal.interfaces';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { SelectCountryComponent } from '../../../shared/components/select-country/select-country.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AsyncPipe } from '@angular/common';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

interface IEditForm { username: string; name?: string; surname?: string;
	gender?: GENDER; country1: Country; country2?: Country; isDouble: boolean; birthdate?: string; bio?: string; }

@Component({
	selector: 'dflgr-profile-edit-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, MatProgressSpinnerModule, MatCardModule, MatButtonModule, FormsModule, MatFormFieldModule, MatInputModule, SelectCountryComponent, MatButtonToggleModule, MatTooltipModule, MatIconModule, TextFieldModule, AsyncPipe],
	templateUrl: './profile-edit-handler.component.html',
	styleUrls: ['./profile-edit-handler.component.scss']
})
export class ProfileEditHandlerComponent extends SubscribableBaseComponent {
	@HostBinding('class.handler') readonly handlerClass = true;

	readonly titleHolder = GENERAL.handlers.user.nameEdit;
	FORM_CONFIG = FORM_CONFIG.registration;
	processing = false;
	readonly GENDERS = GENDER; // expose gender enum to template
	user: User;
	readonly editModel$ = this.initUserData();

	constructor(
		private readonly modalHandler: MatDialog,
		private readonly userProvider: UsersService,
		private readonly alertService: AlertService
	) { super(); }

	private initUserData(): Observable<IEditForm> {
		const buildMonth = (month: number) => (`0${month + 1}`).slice(-2);
		const buildDate = (date: Date) => `${date.getFullYear()}-${buildMonth(date.getMonth())}-${date.getDate()}`;

		return this.userProvider.getMyInfo().pipe(
			tap(me => { this.user = me; }),
			map(me => ({
				username: me.username,
				name: me.name,
				surname: me.surname,
				gender: me.gender,
				country1: me.country1,
				country2: me.country2,
				isDouble: !!me.country2,
				birthdate: me.birth ? buildDate(me.birth) : null,
				bio: me.bio
			}))
		);
	}

	reqSaveProfile(editModel: IEditForm) {
		this.processing = true;
		const { name, surname, gender, country1, country2, isDouble, birthdate, bio } = editModel;
		this.userProvider.editUserDetails({
			gender,
			name: !!name && name !== '' ? name : null,
			surname: !!surname && surname !== '' ? surname : null,
			birthdate: !!birthdate && birthdate !== '' ? birthdate : null,
			country1: country1.id,
			country2: isDouble ? country2.id : null,
			bio: bio === '' ? null : bio
		}).pipe(takeUntil(this.ngUnsubscribe)).subscribe(user => {
			if (user) {
				this.user = user;
				this.sendAlert({
					msg: 'Successfully updated details',
					duration: 3000,
					action: 'Cool'
				});
			} else {
				this.sendAlert({
					msg: 'Could not update details! Please try again later',
					duration: 3000,
					action: 'Damn... ok'
				});
			}
			this.processing = false;
		});
	}

	private couldNotUpdateAvatar() {
		this.sendAlert({
			msg: 'Oops! We had some problem updating your photo. Please, try again.',
			duration: 3000,
			action: 'Ok'
		});
	}

	private sendAlert(alert: IAlertMsg) {
		this.alertService.publish(alert);
	}

	private requestSaveAvatar(base64jpg: string) {
		this.processing = true;
		const base64content = base64jpg?.split(',')[1];
		this.userProvider.setUserAvatar(base64content)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(avatarFile => {
				this.user.avatar = avatarFile;
				const msg = avatarFile ? 'Successfully updated your photo!' : 'Successfully removed your photo!';
				this.sendAlert({ msg, duration: 3000, action: 'Cool' });
				this.processing = false;
			}, () => {
				this.couldNotUpdateAvatar();
				this.processing = false;
			});
	}

	private requestCropAvatar(base64str: string) {
		this.modalHandler.open<CropperModalComponent, ICropperModalData, string>(CropperModalComponent, {
			data: { image: base64str }
		}).afterClosed()
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(base64jpg => {
				if (base64jpg) {
					this.requestSaveAvatar(base64jpg);
				}
			});
	}

	requestDeleteAvatar() {
		this.requestSaveAvatar(null);
	}

	async requestChangeAvatar() {
		try {
			const fourMb = 4145728;
			// Unsafe dataUrl issue: https://github.com/angular/angular/issues/18950
			const base64str = await Utils.onClickSendMedia(fourMb, false, 'image/*');
			this.requestCropAvatar(Array.isArray(base64str) ? base64str[0] : base64str);
		} catch (err) {
			this.sendAlert({ msg: err, action: 'Ok' });
		}
	}

}

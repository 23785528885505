/* src/app/app.component.scss */
:host {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #4dd0e1;
}
.app-toolbar-container .header-nav-icon {
  padding: 0 14px;
}
@media (max-width: 599px) {
  .app-toolbar-container .header-nav-icon {
    display: none;
  }
}
@media (max-width: 599px) {
  ::ng-deep .mat-mdc-snack-bar-handset {
    margin-bottom: 50px !important;
  }
}
main,
#main {
  display: flex;
  overflow-y: auto;
  flex: 1 1 100%;
  background-color: #f6f6f6;
}
nav {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
  justify-content: space-around;
  background-color: #4dd0e1;
  padding: 6px 12px 4px;
  z-index: 10;
}
@media (min-width: 424px) {
  nav {
    width: 400px;
    align-self: center;
  }
}
nav dflgr-nav-icon:nth-of-type(2) {
  margin-right: 28px;
}
nav dflgr-nav-icon:nth-of-type(3) {
  margin-left: 28px;
}
/*# sourceMappingURL=app.component.css.map */

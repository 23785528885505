import { Component, OnInit, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef, input } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import User from '../../../shared/models/user.model';
import { AuthService } from '../../../shared/services/auth.service';
import { FlagsService } from '../../../shared/services/flags.service';
import { GENERAL } from '../../../app.constants';
import { IMenuOptions } from '../../../shared/services/header.interfaces';
import { HandlerActionButtonComponent } from '../../../shared/components/handler-action-button/handler-action-button.component';
import { FlagEventListFilteredComponent } from '../../../shared/components/flag-event-list-filtered/flag-event-list-filtered.component';
import { HeaderHandlerComponent } from '../../../shared/components/header-handler/header-handler.component';

@Component({
	selector: 'dflgr-user-handler',
	standalone: true,
	imports: [RouterOutlet],
	template: `<router-outlet/>`,
	styles: [`:host{ width: 100%; }`]
})
export class UserHandlerComponent { }


@Component({
	selector: 'dflgr-user-events-handler',
	standalone: true,
	imports: [HeaderHandlerComponent, FlagEventListFilteredComponent, HandlerActionButtonComponent],
	templateUrl: './user-handler.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./user-handler.component.scss']
})
export class UserEventsHandlerComponent implements OnInit {
	@HostBinding('class.handler') readonly handlerClass = true;
	readonly user = input<User>(); // from route resolver
	titleHolder: string;

	constructor(
		private readonly authProvider: AuthService,
		private readonly flagsProvider: FlagsService,
		public readonly cdRef: ChangeDetectorRef,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) { }

	get isSelf() {
		return this.user().id === this.authProvider.localUser.id;
	}

	ngOnInit() {
		this.titleHolder = this.isSelf ? 'Profile' : this.user().username;
		this.cdRef.detectChanges();
	}

	get actionButtonAction() {
		return this.isSelf ? this.requestAddFlag.bind(this) : null;
	}

	get handlerOptions(): IMenuOptions[] {
		const options: IMenuOptions[] = [{
			text: 'Countries',
			icon: 'flag',
			forceShowAction: true,
			callback: this.goToFlags.bind(this)
		}];

		if (this.isSelf) {
			options.push({
				text: 'Settings',
				icon: 'settings',
				forceShowAction: true,
				callback: this.navigateToSettings.bind(this)
			});
		}

		return options;
	}

	private goToFlags() {
		this.router.navigate([GENERAL.handlers.user.flags.path], { relativeTo: this.route });
	}

	private navigateToSettings() {
		this.router.navigate([GENERAL.handlers.settings.path]);
	}

	private requestAddFlag() {
		this.flagsProvider.requestAddFlag();
	}

}

<dflgr-header-handler [title]="titleHolder" [backButton]="true"/>
<div class="handler-content">

	@if (editModel$ | async; as editModel) {
		<div class="edit-section edit-avatar">
			<h3>Photo</h3>

			<div class="avatar-container">
				<div class="spinner-container picture-loading">
					@if (processing) {
						<mat-spinner/>
					}
				</div>
				<div class="avatar-inner">
					<div class="avatar-image avatar-inner-half">
						<img matCardImageMedium class="avatar" [src]="user.avatarSrc" alt="User avatar">
					</div>
					<div class="avatar-buttons avatar-inner-half">
						<p><button mat-raised-button (click)="requestChangeAvatar()" [disabled]="processing">Upload new photo</button></p>
						@if (user.avatar) {
							<p><button mat-raised-button (click)="requestDeleteAvatar()" [disabled]="processing">Remove photo</button></p>
						}
					</div>
				</div>
			</div>
		</div>

		<div class="edit-section edit-profile">
			<h3>Public profile details</h3>

			<form #editForm="ngForm" (ngSubmit)="reqSaveProfile(editModel)">
				<div class="form-content">
					<div class="form-block">
						<mat-form-field class="form-field has-mat-form-field">
							<mat-label>Username</mat-label>
							<input matInput type="text" placeholder="Username"
								#fldUser="ngModel" [ngModel]="editModel.username" [disabled]="true"
								name="editUsername" autocomplete="username"/>
						</mat-form-field>
					</div>
					<div class="form-block">
						<mat-form-field class="form-field has-mat-form-field">
							<mat-label>Name</mat-label>
							<input matInput type="text"
								#fldName="ngModel" [(ngModel)]="editModel.name"
								name="editName" placeholder="Name" autocomplete="given-name"
								[maxlength]="FORM_CONFIG.name.max" required/>
							<mat-hint align="end" class="hint-count" aria-live="polite">
								{{fldName.value?.length || 0}} / {{FORM_CONFIG.name.max}}
							</mat-hint>
							<mat-error>This field is required</mat-error>
						</mat-form-field>
						<mat-form-field class="form-field has-mat-form-field">
							<mat-label>Surname</mat-label>
							<input matInput type="text"
								#fldSurname="ngModel" [(ngModel)]="editModel.surname"
								name="editSurname" placeholder="Surname" autocomplete="family-name"
								[maxlength]="FORM_CONFIG.surname.max"/>
							<mat-hint align="end" class="hint-count" aria-live="polite">
								{{fldSurname.value?.length || 0}} / {{FORM_CONFIG.surname.max}}
							</mat-hint>
						</mat-form-field>
					</div>
					<div class="form-block">
						<div class="form-field has-mat-form-field">
							<dflgr-select-country id="country1Input"
								#fldNation1="ngModel" [(ngModel)]="editModel.country1"
								[excluded]="editModel.isDouble && editModel.country2"
								label="{{editModel.isDouble ? 'Nation 1' : 'Nationality'}}" name="editCountry1"
								placeholder="{{editModel.isDouble ? 'Nation 1' : 'Nationality'}}" name="editCountry1"
								required/>
						</div>
						<div class="form-field">
							<mat-button-toggle-group
								#fldDouble name="isDouble" [(ngModel)]="editModel.isDouble" [hideSingleSelectionIndicator]="true">
								<mat-button-toggle [value]="false" matTooltip="Single nationality"><mat-icon>flag</mat-icon></mat-button-toggle>
								<mat-button-toggle [value]="true" matTooltip="Double nationality"><mat-icon>flag</mat-icon><mat-icon>flag</mat-icon></mat-button-toggle>
							</mat-button-toggle-group>
						</div>
						@if (editModel.isDouble) {
							<div class="form-field has-mat-form-field">
								<dflgr-select-country id="country2Input"
									#fldNation2="ngModel" [(ngModel)]="editModel.country2"
									[excluded]="editModel.country1"
									label="Nation 2" name="editCountry2"
									placeholder="Nation 2" name="editCountry2"
									[required]="editModel.isDouble"/>
							</div>
						}
					</div>
					<div class="form-block block-full-width">
						<mat-form-field class="form-field form-full-width has-mat-form-field">
							<mat-label>Bio</mat-label>
							<textarea matInput cdkTextareaAutosize type="text"
								#fldBio="ngModel" [(ngModel)]="editModel.bio"
								name="editBio" placeholder="Bio"
								[maxlength]="FORM_CONFIG.bio.max"></textarea>
							<mat-hint align="end" class="hint-count" aria-live="polite">
								{{fldBio.value?.length || 0}} / {{FORM_CONFIG.bio.max}}
							</mat-hint>
						</mat-form-field>
					</div>
				</div>
				<h3>Private details</h3>
				<div class="form-content">
					<div class="form-block">
						<div class="form-field mat-form-field-wrapper fake-label-container reduce-vertical-space">
							<span class="fake-label">Gender</span>
							<mat-button-toggle-group #fldGender="ngModel"
								[(ngModel)]="editModel.gender" name="gender">
								<mat-button-toggle [value]="GENDERS.female">Female</mat-button-toggle>
								<mat-button-toggle [value]="GENDERS.male">Male</mat-button-toggle>
								<mat-button-toggle [value]="null">Other/Non-binary</mat-button-toggle>
							</mat-button-toggle-group>
						</div>
					</div>
					<div class="form-block">
						<div class="form-field has-mat-form-field">
							<mat-form-field>
								<mat-label>Birthdate</mat-label>
								<input matInput type="date" id="birthdate"
									[(ngModel)]="editModel.birthdate" name="editBirthdate"
									placeholder="Birthdate" autocomplete="bday"/>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="form-actions">
					<button mat-raised-button type="submit" color="accent" [disabled]="editForm.pristine || editForm.invalid || processing">Save details</button>
				</div>
			</form>
		</div>
	} @else {
		<div class="spinner-container"><mat-spinner/></div>
	}

</div>

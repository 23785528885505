/* src/app/users/handlers/profile-edit-handler/profile-edit-handler.component.scss */
.handler-content {
  padding: 5px;
}
.avatar-container {
  position: relative;
  text-align: center;
  margin-bottom: 36px;
}
.avatar-container .avatar-inner {
  display: flex;
  flex-flow: row wrap;
}
.avatar-container .avatar-inner .avatar-image {
  align-self: center;
  min-width: 150px;
}
.avatar-container .avatar-inner .avatar-image img {
  cursor: pointer;
  height: 150px;
  width: 150px;
}
.avatar-container .avatar-inner .avatar-buttons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.avatar-container .avatar-inner .avatar-buttons p {
  padding-left: 24px;
}
.avatar-container .picture-loading {
  position: absolute;
  width: 100%;
}
.form-actions {
  margin: 18px 0 36px;
}
/*# sourceMappingURL=profile-edit-handler.component.css.map */

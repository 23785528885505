import type { Routes } from '@angular/router';
import { PATHS, STATIC_SECTION } from './app.constants';
import { loggedGuard } from './shared/guards/logged.guard';
import { WallHandlerComponent } from './shared/handlers/wall-handler/wall-handler.component';
import { FrontPageComponent } from './shared/handlers/front-page/front-page.component';

export const mainRoutes: Routes = [
	{
		path: 'login',
		component: FrontPageComponent,
		data: { onlyPublic: true },
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.recover,
		loadChildren: () => import('./recover/recover.module'),
		data: { onlyPublic: true },
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.verify,
		loadChildren: () => import('./verify/verify.module')
	},
	{
		path: PATHS.register,
		loadChildren: () => import('./registration/registration.module'),
		data: { onlyPublic: true },
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.wall,
		component: WallHandlerComponent,
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.friends,
		loadChildren: () => import('./friends/friends.module'),
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.settings,
		loadChildren: () => import('./settings/settings.module'),
		canActivate: [loggedGuard]
	},
	{
		path: PATHS.groups,
		loadChildren: () => import('./groups/groups.module'),
		canActivate: [loggedGuard]
	},

	//#region statics
	{
		path: STATIC_SECTION.about,
		loadChildren: () => import('./statics/statics.module')
	},
	{
		path: STATIC_SECTION.terms,
		loadChildren: () => import('./statics/statics.module')
	},
	{
		path: STATIC_SECTION.privacy,
		loadChildren: () => import('./statics/statics.module')
	},
	{
		path: STATIC_SECTION.help,
		loadChildren: () => import('./statics/statics.module')
	},
	{
		path: STATIC_SECTION.content,
		loadChildren: () => import('./statics/statics.module')
	},
	//#endregion statics

	{
		path: PATHS.store,
		loadChildren: () => import('./store/store.module'),
	},
	{
		path: '**',
		component: FrontPageComponent
	},
];

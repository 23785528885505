import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { PATHS } from '../app.constants';
import { loggedGuard } from '../shared/guards/logged.guard';
import { friendGuard } from './guards/friend.guard';
import { userResolver } from './resolvers/user.resolver';
import { selfGuard } from './guards/self.guard';

const routes: Routes = [
	{
		path: `${PATHS.user}/:user`,
		loadComponent: () => import('./handlers/user-handler/user-handler.component').then(m => m.UserHandlerComponent),
		resolve: { user: userResolver },
		canActivate: [loggedGuard, friendGuard],
		children: [
			{
				path: '',
				loadComponent: () => import('./handlers/user-handler/user-handler.component').then(m => m.UserEventsHandlerComponent)
			},
			{
				path: 'edit',
				loadComponent: () => import('./handlers/profile-edit-handler/profile-edit-handler.component').then(m => m.ProfileEditHandlerComponent),
				canActivate: [selfGuard]
			},
			{
				path: PATHS.flags,
				loadComponent: () => import('../shared/handlers/flags-handler/flags-handler.component').then(m => m.FlagsHandlerComponent)
			},
			{
				path: `${PATHS.flags}/:countryId`,
				loadComponent: () => import('./handlers/user-country-handler/user-country-handler.component').then(m => m.UserCountryHandlerComponent)
			}
		]
	}
];


@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class UsersRoutingModule { }

/* src/app/flags/handlers/event-handler/event-handler.component.scss */
.handler-content {
  padding: 5px;
}
@media (min-width: 600px) {
  .handler-content .item,
  .handler-content .card {
    display: block;
    min-width: 20em;
    margin: 1rem auto;
  }
}
/*# sourceMappingURL=event-handler.component.css.map */

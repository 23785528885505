<header>
	<dflgr-header-app class="app-toolbar-container" [title]="title"/>
</header>
<main #main>
	<router-outlet/>
</main>
@if (showLogged) {
<nav>
	@for (link of list; track link) {
	<dflgr-nav-icon
		[badge]="link.badge"
		[path]="link.path"
		[icon]="link.icon"
		[text]="link.text"/>
	}
</nav>
}
